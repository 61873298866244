import React from 'react'
import Formulario from './form'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

import {getBrText} from '../../helpers/helper.rendering'

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact {
                            link
                            value
                        }
                    }
              }
          }
    }`)
    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section class="tasaciones-main">
            <div class="container-fluid">
                <div class="row mt-lg-4 center align-items-center">
                    <div class="col-12">
                        <div class="row content-main align-items-start">
                            <div className="col-12 mt-lg-5 mt-2">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Servicios", route:   "", location: ""},
                                   ]}
                               />
                           </div>
                            <div class="col-lg-8">
                                <h1 class="mb-lg-5">{realEstate.sections.services.title}</h1>
                                <p class="mb-4 mt-5 mt-lg-0">{realEstate.sections.services.subtitle}</p>
                                   
                                <h5>
                                    {getBrText(realEstate.sections.services.paragraph).map((element) => (
                                        <>
                                            {element}
                                            <br />
                                        </>
                                    ))}
                                </h5>
                                <Formulario />
                            </div>
                            <div class="col-lg-3 offset-lg-1 text-right contact-nav d-none d-lg-flex align-items-center">
                                <div class="center-contact">
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.title_contact}</h5>
                                        <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                    </div>
                                    <div className="max-heigth-scroll">
                                        {realEstate.branch_office.map((item,index) => (
                                            <div class="item-contact">
                                                <h5>{realEstate.sections.footer.title_office}{item.name}</h5>
                                                <p >{item.address}</p>
                                                <p >{item.city}</p>
                                                <p >{item.region}</p>
                                                {item.contact.phone.map(phone => (
                                                    <p >Teléfono {phone}</p>
                                                ))}
                                                <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                                <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p>
                                            </div>
                                        ))} 
                                    </div>
                                    <div class="item-contact">
                                        <h5 className='mb-3'>SEGUINOS</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                        <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                                        <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                        <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main